<template>
  <div class="app" id="app">
    <!-- Ladekreis-Anzeige, wenn isLoading true ist -->
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <!-- Inhalt der App, der ausgeblendet wird, wenn isLoading true ist -->
    <div v-else>
      <div style="padding: 25px;" class="centered-element">
        <img src="@/assets/logo.png" class="navbar-logo" alt="Logo" />
      </div>
      <!-- Hauptinhalt -->
      <div style="margin-top:25px;margin-bottom:25px;" class="centered-element">
        <p v-show="!showRepeatButton">
          Die Legalisierung von Cannabis wird erst vorrausichtlich am 01.04.2024 wirksam.
          Die Vorschriften für 100 Meter sind noch nicht in Kraft, dieser Service dient nur zur Veranschaulichung der Schutzzonen für Cannabis des Gesetzesentwurfs (Stand 16.08.2023). Alle Angaben ohne Gewähr!</p>   
      </div>
      <div class="content-container">
        <!-- Kreis -->
        <div class="alarm-button" ref="alarmButton" v-show="showRepeatButton">
        </div>
        <!-- Informationen unter dem Kreis -->
        <div class="poi-info centered-element" v-show="showRepeatButton && poiName !== ''">
          <div>{{ poiName }}</div>
          <div v-if="isWithin100m">Innerhalb 100m</div>
          <div v-else>Außerhalb 100m</div>
        </div>
        <div class="">
          <!-- Button zum Auslösen der Überprüfung -->
          <button @click="checkLocation" v-show="!showRepeatButton" class="check-button">Standortabfrage</button>
          <!-- Wiederholen-Button -->
          <button @click="resetCheck" v-show="showRepeatButton" class="repeat-button">Wiederholen</button>
        </div>
      </div>
      <div style="margin-top:25px;margin-bottom:25px;" class="centered-element">
        <p v-show="!showRepeatButton">
        Mit unserer kostenlosen App erhältst du Echtzeitinformationen über deinen Cannabis-Schutzzonen Status in Deutschland. 
        Dadurch kannst du legal konsumieren und gleichzeitig am Jugendschutz teilnehmen da du die 100 Meter selber prüfen kannst. #PuffPuffPass
      </p>   
      </div>
      <div class="centered-element">
        <a href="https://www.bubatz100.de/informationen">Informationen</a>
        <a href="https://www.abbattista.info/rechtliches-app">Impressum</a>
        <p style="margin-top:25px;margin-bottom:25px;">Made in Germany 🇩🇪</p>   
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      isWithin100m: false,
      poiName: "",
      showRepeatButton: false,
      isLoading: false // Zustand während des Ladens
    };
  },
  methods: {
    async fetchData(lat, lon) {
      this.isLoading = true; // Aktiviert den Ladezustand
      try {
        const response = await axios.get(`https://seashell-app-dr9cr.ondigitalocean.app/check_location?lat=${lat}&lon=${lon}`);
        this.isWithin100m = response.data.is_within_100m;
        this.poiName = response.data.poi_name;
        this.updatePulseColor();
      } catch (error) {
        console.error("Es gab einen Fehler beim Abrufen der Daten: ", error);
        this.poiName = "Fehler beim Laden";
      }
      this.isLoading = false; // Deaktiviert den Ladezustand
    },
    checkLocation() {
      if (navigator.geolocation) {
        this.isLoading = true; // Aktiviere den Ladezustand
        navigator.geolocation.getCurrentPosition((position) => {
          this.fetchData(position.coords.latitude, position.coords.longitude);
          this.showRepeatButton = true; // Zeige den Wiederholen-Button nach der Überprüfung
          this.isLoading = false; // Deaktiviere den Ladezustand nach Abschluss
        }, () => {
          this.poiName = "Zugriff auf den Standort wurde verweigert oder ist nicht möglich.";
          this.isLoading = false; // Deaktiviere den Ladezustand bei einem Fehler
        });
      } else {
        this.poiName = "Geolocation wird von diesem Browser nicht unterstützt.";
        this.isLoading = false; // Deaktiviere den Ladezustand bei einem Fehler
      }
    },
    resetCheck() {
      this.showRepeatButton = false;
      this.poiName = "";
      // Nicht erneut `checkLocation` aufrufen, um Loop zu vermeiden
    },
    updatePulseColor() {
      const alarmButton = this.$refs.alarmButton;
      if (this.isWithin100m) {
        alarmButton.style.boxShadow = "0 0 0 15px rgba(255, 0, 0, 0.7)";
      } else {
        alarmButton.style.boxShadow = "0 0 0 15px #336600";
      }
    }
  }
};
var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://rt.abbattista.info/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '6']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
</script>

<style>
/* Benötigte CSS-Klassen für das Layout */
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ffffff;
  background: repeating-linear-gradient(45deg,#d9d9d900,#d5d5d57a 2.5px,#fff 0,#fff 12.5px);
}



.topbar-navbar {
  height: 170px;
}

.container .navbar-logo {
  max-height: 100px;
  margin: auto; /* Zentrierung des Logos */
}

.content-container, .centered-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.356);
}

/* Benötigter CSS-Stil für den Kreis */
.alarm-button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
}

/* Benötigte CSS-Stile für den Überprüfen-Button */
.check-button {
  background-color: #336600;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.check-button:hover {
  background-color: black;
}

/* Benötigter CSS-Stil für das Wiederholen-Button */
.repeat-button {
  margin-top: -50px; /* Setzt den Button nach oben */
  background-color: #336600;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.repeat-button:hover {
  background-color: black;
}

/* Benötigter CSS-Stil für die Informationen unter dem Kreis */
.poi-info {
  margin-top: 20px;
}

/* Hier fügst du den bereits vorhandenen CSS-Code ein, plus den zusätzlichen für den Loader: */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #336600; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


</style>